import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "customer", "customers", "video" ]

  connect() {
    this.customerTargets.forEach((div, i) => {
      div.style.height = `${div.offsetWidth}px`;
    });

    if(this.hasCustomersTarget) {
      this.initCustomers();
    }
  }

  resizeClientsTiles() {
    this.customerTargets.forEach((div, i) => {
      div.style.height = `${div.offsetWidth}px`;
    });
  }

  initCustomers() {
    this.customerPos = 0;
    this.customerDivs = this.customersTarget.querySelectorAll('.content');
    this.customerDivs.forEach((div, i) => {
      if(i > 0) {
        div.style.display = 'none';
      }
    });
    this.slideCustomers();
  }

  slideCustomers() {
    let that = this;
    this.customerTimeout = setTimeout(() => {
      if(this.customerPos < this.customerDivs.length-1) {
        $(this.customerDivs[this.customerPos]).fadeOut(400, function() {
          that.customerPos += 1;
          $(that.customerDivs[that.customerPos]).fadeIn(400);
        });
      } else {
        $(this.customerDivs[this.customerPos]).fadeOut(400, function() {
          that.customerPos = 0;
          $(that.customerDivs[that.customerPos]).fadeIn(400);
        });
      }
      that.slideCustomers()
    }, 15000)
  }

  previousCustomer() {
    clearTimeout(this.customerTimeout)
    let that = this;
    if(this.customerPos > 0) {
      $(this.customerDivs[this.customerPos]).fadeOut(400, function() {
        that.customerPos -= 1;
        $(that.customerDivs[that.customerPos]).fadeIn(400);
      });
    } else {
      $(this.customerDivs[this.customerPos]).fadeOut(400, function() {
        that.customerPos = that.customerDivs.length-1;
        $(that.customerDivs[that.customerPos]).fadeIn(400);
      });
    }
    this.slideCustomers();
  }

  nextCustomer() {
    clearTimeout(this.customerTimeout)
    let that = this;
    if(this.customerPos < this.customerDivs.length-1) {
      $(this.customerDivs[this.customerPos]).fadeOut(400, function() {
        that.customerPos += 1;
        $(that.customerDivs[that.customerPos]).fadeIn(400);
      });
    } else {
      $(this.customerDivs[this.customerPos]).fadeOut(400, function() {
        that.customerPos = 0;
        $(that.customerDivs[that.customerPos]).fadeIn(400);
      });
    }
    this.slideCustomers();
  }

  toggleVideo() {
    this.videoTarget.paused ? this.videoTarget.play() : this.videoTarget.pause();
    event.preventDefault();
    return false;
  }
}
