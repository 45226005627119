import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "image_picker", "image_wrapper", "image", "imageSid" ]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    let div = document.querySelector(".trix-content");
    if(div) {
      div.querySelectorAll('a').forEach((a, i) => {
        a.setAttribute('target', '_blank');
      });
    }
  }

  selectImage() {
    this.image_pickerTarget.click();
  }

  removeImage() {
    this.imageTarget.src = '';
    this.image_wrapperTarget.classList.add('is-hidden');
    this.imageSidTarget.value = '';
  }

  uploadImage() {
    let uploadsController = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;

    var files = input.files.length;
    var count = 0;

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/system/updates/images/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              uploadsController.imageTarget.src = data.preview;
              uploadsController.image_wrapperTarget.classList.remove('is-hidden');
              uploadsController.imageSidTarget.value = data.sid;
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  countCharacters() {
    let out = event.target.closest('div.field').querySelector('output');
    out.textContent = event.target.value.trim().length;
  }

}
