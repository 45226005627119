import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "firstDiv", "navbar", "hero_top", "business_subscriptions" ]

  connect() {
    let appController = this;
    let appScreenshotWrapper = document.querySelector('.app-screenshot-wrapper');
    if(appScreenshotWrapper) {
      appScreenshotWrapper.addEventListener('keydown', function(event) {
        if(event.keyCode == 27) {
          appController.closeScreenshot();
        }
      });
    }

    if(this.hero_top) {
      if(window.scrollY > this.hero_topTarget.offsetHeight-200) {
        this.navbarTarget.style.backgroundColor = `rgba(255, 255, 255, 1)`;
        this.navbarTarget.classList.add('has-shadow');
      }
    }

    window.onSubmit = function(token) {
      let form = document.getElementById("contact-form");
      Rails.fire(form, 'submit');
    }
  }

  toggleMenu() {
    const btn = document.getElementById('navbarToggle');
    const navbar = document.getElementById('navbar');
    btn.classList.toggle('is-active');
    navbar.classList.toggle('is-active');
  }

  showScreenshot() {
    document.querySelector('img#appScreenshot').src = event.target.dataset.image;
    document.querySelector('.app-screenshot-wrapper').classList.toggle('is-hidden');
    document.querySelector('.app-screenshot-wrapper').focus();
  }

  closeScreenshot() {
    document.querySelector('.app-screenshot-wrapper').classList.toggle('is-hidden');
  }

  onScroll() {
    let appController = this;
    if(window.scrollY > this.hero_topTarget.offsetHeight-200) {
      let arrows = document.querySelector(".arrow");
      if(arrows) {
        var fadeEffect = setInterval(function () {
            if (!arrows.style.opacity) {
                arrows.style.opacity = 1;
            }
            if (arrows.style.opacity > 0) {
                arrows.style.opacity -= 0.1;
            } else {
                clearInterval(fadeEffect);
            }
        }, 200);
      }

      if(this.navbarTarget.dataset.background == 'transparent') {
        this.fadeInNavbar();
      }
    } else {
      if(this.navbarTarget.dataset.background == 'white') {
        this.fadeOutNavbar();
      }
    }
  }

  fadeInNavbar() {
    let navbar = this.navbarTarget;
    navbar.dataset.background = 'white';
    let o = .4;
    var fadeNavbarIn = setInterval(function () {
      if (o < 1) {
        o += 0.1;
        navbar.style.backgroundColor = `rgba(255, 255, 255, ${o})`;
        if(o > 0.8) {
          navbar.classList.add('has-shadow');
        }
      } else {
        clearInterval(fadeNavbarIn);
      }
    }, 50);
  }

  fadeOutNavbar() {
    let navbar = this.navbarTarget;
    navbar.dataset.background = 'transparent';
    let o = 1;
    var fadeNavbarOut = setInterval(function () {
      if (o > .5) {
        o -= 0.1;
        navbar.style.backgroundColor = `rgba(255, 255, 255, ${o})`;

        if(o < 0.7) {
          navbar.classList.remove('has-shadow');
        }
      } else {
        clearInterval(fadeNavbarOut);
        navbar.style.backgroundColor = `rgba(255, 255, 255, .4)`;
      }
    }, 50);
  }

  toggleBusinessSubscription() {
    this.business_subscriptionsTarget.classList.toggle('active')
  }
}
